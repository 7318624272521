var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "iframe-container", attrs: { elevation: "5" } },
    [
      _c("iframe", {
        staticStyle: { "max-height": "100%" },
        attrs: { src: _vm.ReportUrl, allowFullScreen: "true" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }