var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("pga-data-table", {
        attrs: {
          title: "Certifications",
          headers: _vm.headers,
          data: _vm.CertificationsList,
          loading: _vm.isLoading,
          "col-props": ["IsActive", "Type"],
        },
        on: { "row-clicked": _vm.rowClicked },
        scopedSlots: _vm._u([
          {
            key: "column-IsActive",
            fn: function (props) {
              return [
                _c("pga-switch", {
                  attrs: {
                    "pga-aria-label": ["Active", "No Active"],
                    readonly: "",
                  },
                  model: {
                    value: props.row.item.IsActive,
                    callback: function ($$v) {
                      _vm.$set(props.row.item, "IsActive", $$v)
                    },
                    expression: "props.row.item.IsActive",
                  },
                }),
              ]
            },
          },
          {
            key: "column-Type",
            fn: function (props) {
              return [
                _c("pga-type-certification", {
                  model: {
                    value: props.row.item.Type,
                    callback: function ($$v) {
                      _vm.$set(props.row.item, "Type", $$v)
                    },
                    expression: "props.row.item.Type",
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }