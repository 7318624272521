var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.accessibility
        ? _c(
            "pga-bottom-navigation",
            [
              _c(
                "pga-authorized-content",
                { attrs: { permission: "EditCertification" } },
                [
                  _c("pga-create-button", {
                    on: {
                      click: function ($event) {
                        return _vm.Navigate("/certifications/create")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          !_vm.accessibility
            ? _c("pga-certifications-table", {
                attrs: {
                  "certifications-list": _vm.CertificationsList,
                  "is-loading": _vm.isLoading,
                },
              })
            : _c("pga-certifications-table-accessibility", {
                attrs: {
                  "certifications-list": _vm.CertificationsList,
                  "is-loading": _vm.isLoading,
                },
                on: {
                  "create-accessibility": function ($event) {
                    return _vm.Navigate("/certifications/create")
                  },
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }